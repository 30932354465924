$businessphoneprices-go-go-green: #008a7b;
$businessphoneprices-green: #5bc1a4;
$businessphoneprices-bank-vault: #747474;

$fleettrackingquotes-default-button-bgcolor: #0e6158;
$fleettrackingquotes-default-button-hover-bgcolor: #125f56;
$fleettrackingquotes-radio-default-bgcolor: linear-gradient(
    180deg,
    #ffbd12 0%,
    #ffae12 100%
);
$fleettrackingquotes-radio-hover-default-bgcolor: #ffe7d6;
$fleettrackingquotes-radio-border-color: #fe6e05;
$fleettrackingquotes-radio-color: #000;
$fleettrackingquotes-footer-color: #000;
$fleettrackingquotes-header-callcenter-color: #129188;
$fleettrackingquotes-progress-color: #d6e1df;
$fleettrackingquotes-progress-text-color: #0e6158;

:export {
    headerCallCenter: $fleettrackingquotes-header-callcenter-color;
    progressColor: $fleettrackingquotes-progress-color;
    goGoGreen: $businessphoneprices-go-go-green;
    green: $businessphoneprices-green;
    bankVault: $businessphoneprices-bank-vault;
}
