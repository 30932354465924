
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    width: 100vw;
    min-height: calc(100vh - 50px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 20px 20px;
    padding-bottom: 200px;
    @include xl {
        padding-bottom: 100px;
    }

    .form {
        z-index: 5;
        padding: 30px 24px;
        width: 100%;
        max-width: 600px;
        border-radius: 20px;
        background-color: white;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        position: relative;
        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 30px;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 28px !important;
            font-weight: 500 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: center;
            font-weight: 500 !important;
            margin-bottom: 10px;
            margin-top: -20px;
            margin-bottom: 25px !important;
            font-size: 28px !important;
            @include lg {
                margin-left: -50px;
                margin-right: -50px;
            }
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            border-radius: 999px !important;
            background: $fleettrackingquotes-radio-default-bgcolor !important;
            color: black;
            transition: all 0.2s ease-in-out;
            @include lg {
                max-width: 416px !important;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                max-width: 416px;
            }
            > input {
                border-radius: 999px !important;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            margin-top: 10px;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        border: 2px solid black;
                    }
                }
                label {
                    background: $fleettrackingquotes-radio-default-bgcolor;
                    border-radius: 999px;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    flex-direction: row;
                    min-height: 62px;
                    font-weight: 500;

                    & > span {
                        margin-top: 0;
                    }
                    & > div {
                        margin-right: 20px;
                        margin-left: 20px;
                        img {
                            width: 100%;
                        }
                    }
                    &:hover {
                        filter: brightness(1.1);
                    }
                    @include md {
                        > span {
                            @apply text-lg;
                        }
                        min-height: 62px;
                    }
                    @apply text-left;
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                }
                @include lg {
                    margin: 0 10px;
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        background-image: url("/assets/images/mobile-3Opt.jpg");

        @include md {
            background-image: url("/assets/images/background-6Opt.jpg");
        }
    }
}

.step-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 345px;
}
.back-button {
    left: 40px;
    @media screen and (max-width: 1023px) {
        left: 25px;
    }
    @media screen and (max-width: 414px) {
        left: 0;
    }
}
